// extracted by mini-css-extract-plugin
export var container = "style-module--container--LsM3S";
export var stepsContainer = "style-module--stepsContainer--AlZty";
export var stepWrapper = "style-module--stepWrapper--Mb9cy";
export var step = "style-module--step--+eKMQ";
export var even = "style-module--even--v0D3G";
export var textContainer = "style-module--textContainer--ZHotu";
export var textContainer3 = "style-module--textContainer3--DBXI-";
export var bottomImage1 = "style-module--bottomImage1--OZ1Lr";
export var bottomImage3 = "style-module--bottomImage3--4U0uW";
export var odd = "style-module--odd--colNo";
export var topImgage2 = "style-module--topImgage2--rv764";
export var icon = "style-module--icon--EaR40";