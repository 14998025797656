import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import Quotation from '../components/Quotation'
import OurValues from '../components/OurValues'
import WhoWeAre from '../components/WhoWeAre'
import JoinUs from '../components/JoinUs'

const AboutPageTemplate = ({ quotationTitle, quotationText }) => {
  return (
    <>
      <Quotation title={quotationTitle} text={quotationText} />
      <OurValues />
      <WhoWeAre />
      <JoinUs
        title="Vous souhaitez faire décoller votre carrière avec Artelink ?"
        subTitle="Envoyez-nous votre candidature"
        link="mailto:candidature@artelink.com"
        linkText="Envoyer ma candidature"
      />
    </>
  )
}

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <Helmet>
        <title>Une équipe experte pluridisciplinaire à votre écoute.</title>
        <meta name="description" content="Artelink est une équipe à mi-distance entre ingénierie et numérique. Composée d’experts en ingénierie depuis plusieurs décennies et de jeunes entrepreneurs experts de l'intermédiation." />
        {/* Open Graph */}
        <meta name="og:title" content="Artelink - Une équipe experte pluridisciplinaire à votre écoute." />
        <meta name="og:image" content="" />
        <meta name="og:url" content="" />
        <meta name="og:description" content="Artelink est une équipe à mi-distance entre ingénierie et numérique. Composée d’experts en ingénierie depuis plusieurs décennies et de jeunes entrepreneurs experts de l'intermédiation." />
      </Helmet>

      <AboutPageTemplate {...frontmatter} />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        quotationTitle
        quotationText
      }
    }
  }
`
