import React from 'react'
import ReactMarkdown from 'react-markdown'

import ContentWrapper from '../ContentWrapper'

import * as styles from './style.module.sass'

import Logo from '../../img/logo-white.svg'

const Quotation = ({title, text}) => (
  <div className={styles.container}>
    <ContentWrapper>
      <h2 className={styles.title}><ReactMarkdown>{title}</ReactMarkdown></h2>

      <div className={styles.squareContainer}>
        <div className={styles.square} />

        <div className={styles.textSquareContainer}>
          <ReactMarkdown>{text}</ReactMarkdown>
        </div>
      </div>
      <div className={styles.logoContainer}>
        <div className={styles.logoWrapper}>
          <Logo />
        </div>
      </div>

    </ContentWrapper>
  </div>
)

export default Quotation
