import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import ContentWrapper from '../ContentWrapper'

import * as styles from './style.module.sass'

const OurValues = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { componentKey: { eq: "OurValues" } }) {
        frontmatter {
          title
        }
      }
    }
  `)
  const { title } = data.markdownRemark.frontmatter

  return (
    <div className={styles.container}>
      <ContentWrapper>
        <h1><ReactMarkdown>{title}</ReactMarkdown></h1>

        <div className={`${styles.stepsContainer} columns`}>
          <div className={`${styles.stepWrapper} column is-one-quarter-desktop is-half-tablet-only is-full-mobile`}>
            <div className={`${styles.step} ${styles.even}`}>
              <div className={styles.textContainer}>
                <img className={styles.icon} src='/img/icon_human.svg' alt='human' />
                <h2>L'humain au centre</h2>
                <p>Ce n'est pas parce que nous travaillons avec une plateforme digitale que nous supprimons le lien. Artelink est une manière de faciliter les connexions humaines, pas de les supprimer.</p>
                <p>C'est un outil d'échange. Ce qui est vrai pour nos clients, nos partenaires et nos experts, l'est aussi pour nos collaborateurs.</p>
                <p>Ainsi un utilisateur peut joindre un manager d'Artelink dès qu'il en ressent l'envie.</p>
              </div>
              <img className={styles.bottomImage1} src='/img/human.webp' alt='bottom element' />
            </div>
          </div>

          <div className={`${styles.stepWrapper} column is-one-quarter-desktop is-half-tablet-only is-full-mobile`}>
            <div className={`${styles.step} ${styles.odd}`}>
              <img className={styles.topImgage2} src='/img/trust.webp' alt='top element' />
              <div className={styles.textContainer}>
                <img className={styles.icon} src='/img/icon_trust.svg' alt='trust' />
                <h2>La confiance</h2>
                <p>Nous avons conscience que l'approche "internet" peut parfois être synonyme d'appréhension. C'est pourquoi nous échangeons avec vous en toute transparence.</p>
                <p>Pas de faux profils, pas de mensonges ou de sur-estimations.</p>
                <p>Nous avons la mission ou le profil qui vous convient, alors tant mieux. Sinon, nous vous le dirons avec honnêteté.</p>
              </div>
            </div>
          </div>

          <div className={`${styles.stepWrapper} column is-one-quarter-desktop is-half-tablet-only is-full-mobile`}>
            <div className={`${styles.step} ${styles.even}`}>
              <div className={`${styles.textContainer} ${styles.textContainer3}`}>
                <img className={styles.icon} src='/img/icon_ambition.svg' alt='ambition' />
                <h2>L'ambition</h2>
                <p>Artelink n'en est qu'à ses premiers pas. Mais nous espérons pouvoir compter sur votre confiance pour mener cette démarche sur le devant de la scène rapidement. Une plateforme de partage regroupant tous les grands et plus petits acteurs de nos secteurs.</p>
                <p>Ensemble, pour représenter l'excellence européenne de l'ingénierie aussi sur internet.</p>
              </div>
              <img className={styles.bottomImage3} src='/img/ambition.webp' alt='bottom element' />
            </div>
          </div>
        </div>
      </ContentWrapper>
    </div>
  )
}

export default OurValues
