// extracted by mini-css-extract-plugin
export var container = "style-module--container--OxjV2";
export var title = "style-module--title--iyL8G";
export var description = "style-module--description---tWd-";
export var logosContainer = "style-module--logosContainer--0LsmD";
export var logo = "style-module--logo--2+YhP";
export var testimonyContainer = "style-module--testimonyContainer--VZrB2";
export var testimonyWrapper = "style-module--testimonyWrapper--+I6-m";
export var testimonyTitle = "style-module--testimonyTitle--LvnAu";
export var testimonyName = "style-module--testimonyName--SmCzf";
export var testimonyPicture = "style-module--testimonyPicture--5Lv4J";
export var openApostrophes = "style-module--openApostrophes--URYBO";
export var closeApostrophes = "style-module--closeApostrophes--0+uBJ";
export var teamMembers = "style-module--teamMembers--VNgvN";
export var member = "style-module--member--qH-eI";
export var memberContent = "style-module--memberContent--QKwqX";
export var name = "style-module--name--h++cC";
export var job = "style-module--job--dqfFX";