import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import ContentWrapper from '../ContentWrapper'

import nico from '../../img/team/nicolas.webp'
import jordi from '../../img/team/jordi.webp'
import guillaume from '../../img/team/guillaume.webp'
import simon from '../../img/team/simon.webp'
import alexandre from '../../img/team/alexandre.webp'
import clement from '../../img/team/clement.webp'
import raphael from '../../img/team/raphael.webp'
import stephanie from '../../img/team/stephanie.webp'
import emma from '../../img/team/emma.webp'
import lucas from '../../img/team/lucas.webp'

import * as styles from './style.module.sass'

const members = [
  {
    name: 'Nicolas Bertrand',
    job: 'CEO',
    pic: nico
  },
  {
    name: 'Jordi Alerany Canut',
    job: 'Lead Data Scientist',
    pic: jordi
  },
  {
    name: 'Stéphanie Montassier',
    job: 'Responsable des partenariats // Freelance // PME',
    pic: stephanie
  },
  {
    name: 'Guillaume Bertrand',
    job: 'Product Manager',
    pic: guillaume
  },
  {
    name: 'Simon Robert',
    job: 'Lead Developer',
    pic: simon
  },
  {
    name: 'Alexandre Rio',
    job: 'Lead Developer',
    pic: alexandre
  },
  {
    name: 'Clément Lemaire',
    job: 'Growth / acquisition',
    pic: clement
  },
  {
    name: 'Raphaël Didier',
    job: 'Fullstack Developer',
    pic: raphael
  },
  {
    name: 'Emma Hoareau',
    job: 'Community Manager',
    pic: emma
  },
  {
    name: 'Lucas Sola',
    job: 'Fullstack Developer',
    pic: lucas
  }
]

const WhoWeAre = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { componentKey: { eq: "whoweare" } }) {
        frontmatter {
          title
          testimonyTitle
          testimony
          testimonyName
          testimonyPicture {
            publicURL
          }
        }
      }
    }
  `)
  const {
    title,
    testimonyTitle,
    testimony,
    testimonyName,
    testimonyPicture
  } = data.markdownRemark.frontmatter

  return (
    <div className={styles.container}>
      <ContentWrapper>
        <h2 className={styles.title}><ReactMarkdown>{title}</ReactMarkdown></h2>

        <div className={styles.teamMembers}>
          {
            members.map((m) => (
              <div className={styles.member}>
                <div className={styles.memberContent}>
                  <img alt={m.name} src={m.pic} />
                  <div className={styles.name}>{m.name}</div>
                  <div className={styles.job}>{m.job}</div>
                </div>
              </div>  
            ))
          }
        </div>
      </ContentWrapper>
      <div className={styles.testimonyContainer}>
        <ContentWrapper>
          <div className={styles.testimonyWrapper}>
            <div className={styles.openApostrophes}>‘‘</div>
            <h3 className={styles.testimonyTitle}>{testimonyTitle}</h3>
            <p>{testimony}</p>
            <p className={styles.testimonyName}>{testimonyName}</p>
            <div className={styles.closeApostrophes}>‘‘</div>
            <img className={styles.testimonyPicture} src={testimonyPicture.publicURL} alt={`Portrait de ${testimonyName}`} />
          </div>
        </ContentWrapper>
      </div>
    </div>
  )
}

export default WhoWeAre
